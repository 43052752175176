import React, { useState } from "react";
import RegisterForm from "../components/RegisterForm";
import { Dialog, DialogContent } from "@mui/material";

const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      className="footer text-center position-relative"
      style={{
        backgroundColor: "#333",
        color: "#fff",
        padding: "40px 20px",
        marginTop: "50px", // Adds spacing from the previous component
      }}
    >
      <h2 className="fw-bold">
        Reserva Hoy y Dale a tu Mascota la Mejor Experiencia
      </h2>
      <button
        className="btn btn-lg mt-4 register-button"
        onClick={handleOpen}
        style={{
          border: "2px solid white",
          color: "white",
          backgroundColor: "transparent",
          padding: "10px 20px",
          borderRadius: "5px",
          transition: "all 0.3s ease",
        }}
      >
        Regístrate Ahora
      </button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <RegisterForm />
        </DialogContent>
      </Dialog>

      {/* Trademark and Link */}
      <p
        className="position-absolute"
        style={{
          bottom: "10px",
          right: "10px",
          fontSize: "14px",
          margin: "0",
        }}
      >
        
        <a
          href="https://www.foeira.com"
          style={{ color: "#fff", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          by Foeira™
        </a>
      </p>

      {/* Hover styles */}
      <style>
        {`
          .register-button:hover {
            background-color: white;
            color: red;
            border-color: white;
          }
        `}
      </style>
    </div>
  );
};

export default Footer;
