import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../assets/casachica1.jpg'; // Example image import

const Room = ({ room }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="row bs">
      <div className="col-md-4">
        <img src={room.imageurls[0]} className="smallimg" alt={`${room.name}`} />
      </div>
      <div className="col-md-7">
        <h1>{room.name}</h1>
        <p>Max count: {room.maxcount}</p>
        <p>Type: {room.type}</p>

        <div style={{ float: 'right' }}>
          <Button variant="secondary" onClick={handleShow}>
            View Details
          </Button>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{room.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel>
              {/* First slide */}
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ExampleCarouselImage} // Replace with room.imageurls[0] if dynamic
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
              </Carousel.Item>

              {/* Second slide */}
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={room.imageurls[1]} // Use dynamic image source
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <h3>Second slide label</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
              </Carousel.Item>

              {/* Third slide */}
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={room.imageurls[2]} // Use dynamic image source
                  alt="Third slide"
                />
                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Room;
