import React, { useState } from "react";
import RegisterForm from "../components/RegisterForm";
import Footer from "../components/Footer"; // Import the new Footer component
import coverImage from "../assets/20240727_183322.jpg";
import Image2 from "../assets/20240728_150556.jpg";
import Image3 from "../assets/20250105_153415.jpg";
import Image4 from "../assets/20240727_183400.jpg";
import Image5 from "../assets/20240727_183407.jpg";
import Image6 from "../assets/20240727_182951_028.jpg";
import { Dialog, DialogContent } from "@mui/material";

const LandingScreen = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // External image URL
  const spaImage = "https://foeira.com/imagesmac/20241227_172440.jpg";

  return (
    <div className="homepage">
      {/* Cover Section */}
      <div className="cover-section text-center">
        <div className="cover-image-container">
          <img src={coverImage} alt="Dog Hotel Cover" className="cover-image" />
          <div className="cover-overlay d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-white fw-bold display-4">
              Bienvenido al Mejor Hotel Canino
            </h1>
            <p className="text-white fs-5 mt-3">
              Donde tu mascota se sentirá como en casa.
            </p>
            <button className="btn btn-lg mt-4" onClick={handleOpen}>
              Regístrate Ahora
            </button>
          </div>
        </div>
      </div>

      {/* Information Sections */}
      <div className="container mt-5">
        {/* Section 1 */}
        <div className="row g-4 align-items-center">
          <div className="col-md-6">
            <img src={spaImage} alt="Spa Dog" className="grooming-image" />
          </div>
          <div className="col-md-6">
            <h2 className="fw-bold">Servicios de Lujo</h2>
            <p className="mt-3 fs-5">
              Desde paseos personalizados hasta sesiones de spa para perros,
              nuestro hotel ofrece la mejor experiencia para tu mejor amigo.
            </p>
          </div>
        </div>

        {/* Section 2 */}
        <div className="row g-4 align-items-center mt-5">
          <div className="col-md-6 order-md-2">
            <img src={Image2} alt="Modern Facilities" className="grooming-image" />
          </div>
          <div className="col-md-6 order-md-1">
            <h2 className="fw-bold">Instalaciones Modernas</h2>
            <p className="mt-3 fs-5">
              Contamos con áreas amplias, seguras y con cámaras en vivo para que
              puedas monitorear a tu mascota en todo momento.
            </p>
          </div>
        </div>

        {/* Section 3 */}
        <div className="row g-4 align-items-center mt-5">
          <div className="col-md-6">
            <img src={Image3} alt="Healthy Food" className="grooming-image" />
          </div>
          <div className="col-md-6">
            <h2 className="fw-bold">Alimentación Saludable</h2>
            <p className="mt-3 fs-5">
              Preparaciones saludables y adaptadas a la dieta de cada mascota
              para asegurar una estancia cómoda y feliz.
            </p>
          </div>
        </div>

        {/* Section 4 */}
        <div className="row g-4 align-items-center mt-5">
          <div className="col-md-6 order-md-2">
            <img src={Image4} alt="Exercise Zone" className="grooming-image" />
          </div>
          <div className="col-md-6 order-md-1">
            <h2 className="fw-bold">Zona de Ejercicio</h2>
            <p className="mt-3 fs-5">
              Una amplia zona de ejercicios para que tu perro disfrute de juegos
              al aire libre y mantenga su salud física.
            </p>
          </div>
        </div>

        {/* Section 5 */}
        <div className="row g-4 align-items-center mt-5">
          <div className="col-md-6">
            <img src={Image5} alt="Grooming Station" className="grooming-image" />
          </div>
          <div className="col-md-6">
            <h2 className="fw-bold">Estación de Grooming</h2>
            <p className="mt-3 fs-5">
              Un espacio dedicado para el cuidado y limpieza de tu mascota, con
              atención especializada y productos premium.
            </p>
          </div>
        </div>

        {/* Section 6 */}
        <div className="row g-4 align-items-center mt-5">
          <div className="col-md-6 order-md-2">
            <img src={Image6} alt="Rest Zone" className="grooming-image" />
          </div>
          <div className="col-md-6 order-md-1">
            <h2 className="fw-bold">Zona de Descanso</h2>
            <p className="mt-3 fs-5">
              Áreas cómodas y seguras para el descanso de tu mascota, equipadas
              con camas suaves y mantas cálidas.
            </p>
          </div>
        </div>
      </div>

      {/* Reusable Footer Component */}
      <Footer />

      {/* Modal for Registration Form */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <RegisterForm />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LandingScreen;
