import React from "react";
import { FaGlobe } from "react-icons/fa"; // Globe icon
import { useTranslation } from "react-i18next"; // Import translation hook

function Navbar() {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Change language
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <a className="navbar-brand" href="/">
          Macario´s
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {/* Left-aligned navigation (empty in this case) */}
          <ul className="navbar-nav"></ul>

          {/* Right-aligned Register and Login */}
          <ul className="navbar-nav ms-auto"> {/* ms-auto pushes the list to the right */}
            <li className="nav-item">
              <a className="nav-link" href="/register">
                Register
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/login">
                Login
              </a>
            </li>
          </ul>

          {/* Globe Icon with Language Dropdown */}
          <ul className="navbar-nav ms-3">
            <li className="nav-item dropdown">
              <button
                className="btn btn-outline-primary dropdown-toggle d-flex align-items-center"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ border: "none", background: "transparent" }}
              >
                <FaGlobe size={20} className="me-2" />
                <span>Language</span>
              </button>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
                <li>
                  <button
                    className="dropdown-item d-flex align-items-center"
                    onClick={() => changeLanguage("es")}
                  >
                    🇲🇽 <span className="ms-2">Español (México)</span>
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item d-flex align-items-center"
                    onClick={() => changeLanguage("en")}
                  >
                    🇺🇸 <span className="ms-2">English (USA)</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
