import React, { useState } from "react";

const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Earth's radius in km
  const toRad = (value) => (value * Math.PI) / 180;
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon1 - lon2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

const RegisterForm = () => {
  const [zipCode, setZipCode] = useState("");
  const [isWithinRange, setIsWithinRange] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showRegistrationFields, setShowRegistrationFields] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    address: "",
    dogName: "",
    breed: "",
    weight: "",
    weightUnit: "kg", // Default weight unit
    sex: "",
    startDate: "",
    endDate: "",
    dogPhoto: null,
  });

  const dogBreeds = [
    "Labrador Retriever",
    "German Shepherd",
    "Golden Retriever",
    "Bulldog",
    "Poodle",
    "Beagle",
    "Rottweiler",
    "Yorkshire Terrier",
    "Boxer",
    "Dachshund",
    "Shih Tzu",
    "Doberman",
    "Siberian Husky",
    "Great Dane",
    "Chihuahua",
    "Pug",
  ];

  const myLocation = {
    lat: parseFloat(process.env.REACT_APP_HOTEL_LAT),
    lon: parseFloat(process.env.REACT_APP_HOTEL_LON),
  };
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const validZipCodes = process.env.REACT_APP_VALID_ZIPCODES.split(",");

  const getCoordinatesByZipCode = async (zip) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&components=country:MX&key=${apiKey}`
    );
    const data = await response.json();

    if (data.status === "OK" && data.results.length > 0) {
      const addressComponents = data.results[0].formatted_address;
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lon: lng, address: addressComponents };
    } else {
      throw new Error("Invalid zip code or no data found in Mexico.");
    }
  };

  const validateZipCode = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setShowRegistrationFields(false);

    if (validZipCodes.includes(zipCode)) {
      setIsWithinRange(true);
      setFormData({ ...formData, address: `Address for ${zipCode}` });
      setShowRegistrationFields(true);
      return;
    }

    try {
      const { lat, lon, address } = await getCoordinatesByZipCode(zipCode);
      const distance = haversineDistance(myLocation.lat, myLocation.lon, lat, lon);

      if (distance <= 20) {
        setIsWithinRange(true);
        setFormData({ ...formData, address });
        setShowRegistrationFields(true);
      } else {
        setIsWithinRange(false);
        setErrorMessage(`The location is ${distance.toFixed(2)} km away and exceeds 20 km.`);
      }
    } catch (error) {
      setIsWithinRange(false);
      setErrorMessage(error.message || "Failed to validate zip code. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, dogPhoto: file });
  };

  return (
    <div className="container">
      <h3>Register Now</h3>
      <form onSubmit={validateZipCode}>
        <div className="form-group">
          <label>Enter Your Zip Code</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Validate Location</button>
      </form>

      {isWithinRange === true && <p style={{ color: "green" }}>Zip code is valid. Please proceed with registration.</p>}
      {isWithinRange === false && <p style={{ color: "red" }}>{errorMessage}</p>}

      {showRegistrationFields && (
        <form>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              name="address"
              value={formData.address}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Dog's Name</label>
            <input
              type="text"
              className="form-control"
              name="dogName"
              value={formData.dogName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Breed</label>
            <select
              className="form-control"
              name="breed"
              value={formData.breed}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Breed</option>
              {dogBreeds.map((breed, index) => (
                <option key={index} value={breed}>
                  {breed}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Weight</label>
            <div className="d-flex">
              <input
                type="number"
                className="form-control"
                name="weight"
                value={formData.weight}
                onChange={handleInputChange}
                placeholder="Enter weight"
                required
              />
              <select
                className="form-control ms-2"
                name="weightUnit"
                value={formData.weightUnit}
                onChange={handleInputChange}
                required
              >
                <option value="kg">kg</option>
                <option value="lbs">lbs</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>Sex</label>
            <select
              className="form-control"
              name="sex"
              value={formData.sex}
              onChange={handleInputChange}
              required
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label>Initial Date</label>
            <input
              type="date"
              className="form-control"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Final Date</label>
            <input
              type="date"
              className="form-control"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Take a Picture of Your Dog</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              capture="environment"
              onChange={handleFileChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-success mt-3">Submit</button>
        </form>
      )}
    </div>
  );
};

export default RegisterForm;
