
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom'
import HomeScreen from './screens/HomeScreen';
import LandingScreen from './screens/LandingScreen';

function App() {
  return (
    <div className="App">
   <Navbar/>

   <BrowserRouter>
   <Routes>
   <Route path='/home' exact Component={HomeScreen}/>
   <Route path='/' exact Component={LandingScreen}/>

   </Routes>
   
   </BrowserRouter>

    </div>
  );
}

export default App;
