import React, { useState, useEffect } from "react";
import axios from "axios";
import Room from "../components/Room";

function HomeScreen() {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get("/api/rooms/getallrooms");
        setRooms(response.data);
        setLoading(false); // Stop loading after data is fetched
      } catch (err) {
        console.error(err);
        setError("Failed to fetch rooms");
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchRooms(); // Call the async function
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        {loading ? (
          <h2>Loading rooms...</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          rooms.map((room) => {
            return <div className="col-md-9 mt-2">
                <Room room={room}/>



            </div>;
          })
        )}
      </div>
    </div>
  );
}

export default HomeScreen;
